<template>
<div class="sm:block hidden">
  <el-dialog v-model="setConfirmationModal" width="40%" center :before-close="closeConfirmationModal" destroy-on-close>
       <el-result
            icon="info"
            title="Attention"
            :subTitle=Subtitle
        >
        </el-result>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeConfirmationModal" class="py-2 px-5 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="verifyCode" class="py-2 px-4 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block">
  <el-dialog v-model="setConfirmationModal" width="85%" center :before-close="closeConfirmationModal" destroy-on-close>
       <el-result
            icon="info"
            title="Attention"
            :subTitle= YearOfCode
        >
        </el-result>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeConfirmationModal" class="py-2 px-5 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="verifyCode" class="py-2 px-4 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  
  mounted() {
  },
  props:{
    setConfirmationModal : Boolean,
    YearOfCode : String,
    verifyCode : Function,
    closeConfirmationModal: Function
  },

  methods: {
    
    
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    Subtitle(){
        return this.YearOfCode
    }
  },

}
</script>

<style>

</style>